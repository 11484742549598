import store from "@/store";

export default function (to, from, next) {
    const tokenPair = store.getters.getTokenPair;

    const tokenExist =
        tokenPair != null &&
        tokenPair.access != null &&
        tokenPair.access !== "";

    if (!tokenExist) {
        next({ name: "Login" });
        return;
    }

    const decodedAccessToken = decodeAccessToken(tokenPair.access);
    const decodedRefreshToken = decodeAccessToken(tokenPair.refresh);

    const isAccessTokenExpired = isTokenExpired(decodedAccessToken.exp);
    const isRefreshTokenExpired = isTokenExpired(
        decodedRefreshToken.expiration
    );

    if (isAccessTokenExpired) {
        store.dispatch("refresh").then(() => {
            next();
        });
        return;
    }

    if (isRefreshTokenExpired) {
        next({ name: "Login" });
        return;
    }

    next();

    function decodeAccessToken(token) {
        try {
            const tokenPayload = token.split(".")[1];
            const base64 = tokenPayload.replace("-", "+").replace("_", "/");

            return JSON.parse(atob(base64));
        } catch (error) {
            return null;
        }
    }

    // function decodeRefreshToken(token) {
    //     try {
    //         const tokenPayload = atob(token).split(".")[0];
    //         console.log("tokenPayload", tokenPayload);
    //         return JSON.parse(atob(tokenPayload));
    //     } catch (error) {
    //         return null;
    //     }
    // }

    function isTokenExpired(tokenExpireDate) {
        const currentTime = Date.now() / 1000;
        return tokenExpireDate < currentTime;
    }
}
