<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header pb-0">
            <h6>Поиск клиента</h6>
          </div>
          <div class="card-body pt-2">
            <form role="form" class="text-start mt-3" @submit.prevent="findConsumer">
              <div class="row">
                <div class="col-4">
                  <material-input
                      id="consumer_public_id"
                      type="text"
                      label="Публичный ID"
                      name="consumer_public_id"
                      v-model="publicId"
                  />
                </div>
                <div class="col-8">
                  <material-button variant="gradient" color="info" type="submit" @click.prevent="findConsumer">Найти
                  </material-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-5">
      <div class="col-lg-4 col-md-6 col-sm-6 mt-lg-0 mt-4" v-for="consumer in consumers" :key="consumer.id">
        <div class="card mb-4">
          <div class="card-header p-3 pt-2">
            <div
                class="icon icon-lg icon-shape shadow text-center border-radius-xl mt-n4 position-absolute bg-gradient-success shadow-success">
              <i class="material-icons opacity-10 text-white" aria-hidden="true">person</i></div>
            <div class="pt-1 mt-3 text-end">
              <p class="text-sm mb-0 text-capitalize">{{ consumer.publicId || "-" }}</p>
              <h6 class="mb-0">{{ consumer.name || "-" }}</h6>
            </div>
          </div>
          <hr class="dark horizontal my-0">
          <div class="card-footer p-3 text-end">
            <material-button
                variant="gradient"
                color="dark"
                type="button"
                :disabled="this.joinBtnDisable"
                @click.prevent="joinConsumer(consumer.id)"
            >Подключить
            </material-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import index from "vuex";

import {mask} from "vue-the-mask";
import {API_HOST} from "@/config";

import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";

export default {
  name: "ConsumerJoin",
  inject: ["notification"],
  computed: {
    index() {
      return index;
    },
  },
  data() {
    return {
      publicId: "",
      consumers: [],
      joinBtnDisable: false,
    }
  },
  directives: {mask},
  mounted() {
    this.$store.commit("setBreadcrumbs", ["Клиенты", "Подключение клиента"]);
  },
  beforeUnmount() {
    this.$store.commit("setBreadcrumbs", []);
  },
  components: {
    MaterialButton,
    MaterialInput,
  },
  methods: {
    findConsumer() {
      this.consumers = [],
          axios
              .get(API_HOST + "/v1/client/consumers/find/" + this.publicId, {
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": "Bearer " + this.$store.getters.getTokenPair.access,
                },
              })
              .then((response) => {
                if (response.data.found.length === 0) {
                  this.notification.info("Пользователи не найдены")
                } else {
                  this.consumers = response.data.found;
                }
              })
              .catch((error) => {
                if (error.response) {
                  this.notification.error(error.response.data.message)
                } else {
                  this.notification.error("Неизвестная ошибка")
                }

                console.warn(error);
              });
    },
    joinConsumer(consumerId) {
      this.joinBtnDisable = true;
      axios
          .post(API_HOST + "/v1/client/consumers/join", {
            "id": consumerId
          }, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + this.$store.getters.getTokenPair.access,
            },
          })
          .then(() => {
            this.joinBtnDisable = false
            this.$router.push({
              path: `${'/consumers/consumer/' + consumerId}`,
            })
          })
          .catch((error) => {
            this.joinBtnDisable = false
            if (error.response) {
              this.notification.error(error.response.data.message);
            }

            console.warn(error);
          });
    }
  },
};
</script>
