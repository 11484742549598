<template>
  <div class="position-fixed bottom-1 end-1 z-index-2">
    <material-snackbar
        v-if="snackbar === 'success'"
        title="Успшно"
        :description="successAlert"
        :icon="{ component: 'done', color: 'white' }"
        color="success"
        :close-handler="closeSnackbar"
    />
    <material-snackbar
        v-if="snackbar === 'danger'"
        title="Ошибка"
        :description="errorAlert"
        :icon="{ component: 'campaign', color: 'white' }"
        color="danger"
        :close-handler="closeSnackbar"
    />
    <material-snackbar
        v-if="snackbar === 'info'"
        title="Информация"
        :description="infoAlert"
        :icon="{ component: 'notifications', color: 'white' }"
        color="info"
        :close-handler="closeSnackbar"
    />
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import {NOTIFICATION_TIMEOUT} from "@/config";

export default {
  name: "Notification",
  components: {
    MaterialSnackbar,
  },
  data() {
    return {
      status: {
        INACTIVE: "Неактивный",
        ACTIVE: "Активный",
      },
      connectionCodes: [],

      snackbar: null,
      successAlert: "",
      errorAlert: "",
      infoAlert: "",
    }
  },
  methods: {
    closeSnackbar() {
      this.snackbar = null;
    },
    error(message) {
      this.snackbar = 'danger'
      this.errorAlert = message

      setTimeout(() => {
        this.closeSnackbar()
        this.errorAlert = ""
      }, NOTIFICATION_TIMEOUT)
    },
    success(message) {
      this.snackbar = 'success'
      this.successAlert = message

      setTimeout(() => {
        this.closeSnackbar()
        this.successAlert = ""
      }, NOTIFICATION_TIMEOUT)
    },
    info(message) {
      this.snackbar = 'info'
      this.infoAlert = message

      setTimeout(() => {
        this.closeSnackbar()
        this.infoAlert = ""
      }, NOTIFICATION_TIMEOUT)
    },
  }
}
</script>
