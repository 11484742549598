<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div class="bg-gradient-info shadow-success border-radius-lg py-3 d-flex justify-content-between">
              <h6 class="text-white ps-3 pt-1">Ключи подключения</h6>
              <div
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Нажмите чтобы добавить новый код подключения"
                  class="rounded-circle p-1 mx-3 bg-white cursor-pointer"
                  @click="createConnectionCode"
              >
                <plus-icon :size=30 fill-color="#49A3F1"/>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2 overflow-y-scroll" style="max-height: 400px;">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                <tr>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                    Код
                  </th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                    Статус
                  </th>

                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                    Дата создания
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="connectionCode in connectionCodes"
                    :key="connectionCode.key"
                    :class="connectionCode.status === 'ACTIVE' ? `text-success` : ``"
                >
                  <td>
                    <div
                        v-if="connectionCode.status === 'ACTIVE'"
                        class="d-flex gap-3 align-items-center text-xs font-weight-bold mb-0 px-2"
                    >
                      <qrcode-vue :value="connectionCode.key" :size=100 level="H"/>

                      {{ connectionCode.key || "-" }}
                    </div>
                    <p v-else class="text-xs font-weight-bold mb-0 px-2">
                      {{ connectionCode.key || "-" }}
                    </p>
                  </td>
                  <td>
                    <p class="text-xs font-weight-bold mb-0">
                      {{ status[connectionCode.status] || "-" }}
                    </p>
                  </td>
                  <td>
                    <p class="text-xs font-weight-bold mb-0">
                      {{ formatDate(connectionCode.created) || "-" }}
                    </p>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlusIcon from "vue-material-design-icons/Plus.vue"
import QrcodeVue from "qrcode.vue"
import axios from "axios"
import {API_HOST} from "@/config"

export default {
  name: "ConsumerConnectionKeys",
  inject: ["notification"],
  components: {
    PlusIcon,
    QrcodeVue,
  },
  data() {
    return {
      status: {
        INACTIVE: "Неактивный",
        ACTIVE: "Активный",
      },
      connectionCodes: [],
    }
  },
  methods: {
    formatDate(dateString) {
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }
      return new Date(dateString).toLocaleDateString('ru-RU', options)
    },
    getConnectionKeys() {
      axios
          .get(
              API_HOST + "/v1/client/consumers/" + this.$route.params.id + "/keys",
              {
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": "Bearer " + this.$store.getters.getTokenPair.access,
                },
              }
          )
          .then((response) => {
            this.connectionCodes = response.data.keys
          })
          .catch((error) => {
            console.warn(error)
          })
    },
    createConnectionCode() {
      axios
          .put(API_HOST + "/v1/client/consumers/" + this.$route.params.id + "/create_key",
              this.$route.params.id,
              {
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": "Bearer " + this.$store.getters.getTokenPair.access,
                },
              }
          )
          .then(() => {
            this.getConnectionKeys()
            this.notification.success("Код подключения успешно добавлен!")
          })
          .catch((error) => {
            console.warn(error)

            if (error.response) {
              this.notification.error(error.response.data.message)
            } else {
              this.notification.error("Ошибка при создании кода подключения! Повторите запрос позже.")
            }
          })
    },
  },
  mounted() {
    this.getConnectionKeys()
  },
}
</script>
