<template>
  <div v-if="!error404" class="container-fluid">
    <div
        class="page-header min-height-300 border-radius-xl mt-4"
        style="
          background-image: url('https://images.unsplash.com/photo-1531512073830-ba890ca4eba2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80');
        "
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
    <div class="card card-body mx-3 mx-md-4 mt-n6">
      <div class="row gx-4">
        <div class="col-auto">
          <card-account-details-outline-icon
              :size=70
          />
        </div>
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1">{{ this.consumer.name }}</h5>
            <p class="mb-0 font-weight-normal text-sm">
              {{ this.consumer.title }}
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mt-3 row">
          <div class="col-12 col-md-6 col-xl-6 mt-md-0 mt-4 position-relative">
            <div class="h-100">
              <div class="p-3 pb-0 card-header"></div>
              <div class="p-3 card-body">
                <ul class="list-group">
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">ID:</strong>
                    &nbsp;{{ consumer.id }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Состояние:</strong>
                    &nbsp;<span
                      :class="consumer.state === 'delete' ? 'text-danger' : 'text-success'"
                      class="text-bold"
                  >{{ consumer.state === 'delete' ? "Удален" : "Активен" }}</span>
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Дата создания:</strong>
                    &nbsp;{{ formatDate(consumer.created) }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Дата редактирования:</strong>
                    &nbsp;{{ formatDate(consumer.updated) }}
                  </li>
                </ul>
                <form role="form" class="text-start mt-3" @submit.prevent="updateConsumer">
                  <fieldset :disabled="formDisable">
                    <div class="mb-3">
                      <material-input
                          id="consumer_uid"
                          type="text"
                          label="UID"
                          name="consumer_uid"
                          :isRequired="true"
                          v-model="consumer.uid"
                          value="{{ consumer.uid }}"
                      />
                    </div>
                    <div class="mb-3">
                      <material-input
                          id="consumer_name"
                          type="text"
                          label="Имя"
                          name="consumer_name"
                          :isRequired="true"
                          v-model="consumer.name"
                          value="{{ consumer.name }}"
                      />
                    </div>
                    <div class="mb-3">
                      <material-input
                          id="consumer_title"
                          type="text"
                          label="Заголовок"
                          name="consumer_title"
                          :isRequired="true"
                          v-model="consumer.title"
                          value="{{ consumer.title }}"
                      />
                    </div>
                    <div class="mb-3">
                      <material-input
                          id="consumer_phone"
                          type="text"
                          label="Телефон"
                          name="consumer_phone"
                          v-model="consumer.phone"
                          value="{{ consumer.phone }}"
                      />
                    </div>
                    <div class="mb-3">
                      <material-input
                          id="consumer_email"
                          type="email"
                          label="Email"
                          name="consumer_email"
                          v-model="consumer.email"
                          value="{{ consumer.email }}"
                      />
                    </div>
                    <div class="mb-3">
                      <MaterialDatePicker
                          id="birthday"
                          name="birthday"
                          label="День рождения"
                          v-model="consumer.birthday"
                          value="{{ consumer.birthday }}"
                      />
                    </div>
                    <div class="my-4">
                      <material-button variant="gradient" color="info" type="submit"
                                       v-if="!readOnly">Сохранить
                      </material-button>
                    </div>
                  </fieldset>
                </form>
                <div class="d-flex flex-row align-items-center consumer-actions">
                  <material-button variant="gradient" color="warning"
                                   @click.prevent="unJoinConsumer">Отключить
                  </material-button>
                  <material-button variant="gradient" color="danger" v-if="!readOnly"
                                   @click.prevent="deleteConsumer">Удалить
                  </material-button>
                </div>
              </div>
            </div>
            <hr class="vertical dark"/>
          </div>
          <div class="col-12 col-md-6 col-xl-6 mt-md-0 mt-4 position-relative">
            <ConnectionCodes/>
            <DevicesList/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <NotFound/>
  </div>
</template>

<script>
import axios from "axios"
import {mask} from "vue-the-mask"

import MaterialInput from "@/components/MaterialInput.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import MaterialDatePicker from "@/components/MaterialDatePicker.vue"
import ConnectionCodes from "./components/consumer/ConnectionCodes.vue"
import DevicesList from "./components/consumer/DevicesList.vue"
import NotFound from "./NotFound.vue"

import CardAccountDetailsOutlineIcon from "vue-material-design-icons/CardAccountDetailsOutline.vue"

import setTooltip from "@/assets/js/tooltip.js"
import {API_HOST} from "@/config"

export default {
  name: "Consumer",
  inject: ["notification"],
  data() {
    return {
      formDisable: false,
      readOnly: false,
      error404: false,
      consumer: {},
    }
  },
  components: {
    NotFound,
    DevicesList,
    ConnectionCodes,

    MaterialDatePicker,
    MaterialInput,
    MaterialButton,

    CardAccountDetailsOutlineIcon,
  },
  directives: {mask},
  methods: {
    formatDate(dateString) {
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }
      return new Date(dateString).toLocaleDateString('ru-RU', options)
    },
    loadConsumer() {
      axios
          .get(API_HOST + "/v1/client/consumers/" + this.$route.params.id, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + this.$store.getters.getTokenPair.access,
            },
          })
          .then((response) => {
            this.consumer = response.data

            this.$store.commit("setBreadcrumbs", [
              "Клиенты",
              `${this.consumer.name} (${this.consumer.id})`,
            ])

            if (this.consumer.state === "delete") {
              this.formDisable = true
              this.readOnly = true
            }
          })
          .catch((error) => {
            if (error.response && (error.response.status === 404 || error.response.status === 400)) {
              this.error404 = true
            }
            console.warn(error)
          })
    },
    deleteConsumer() {
      if (confirm("Вы действительно хотите удалить клиента")) {
        this.formDisable = true
        axios
            .delete(API_HOST + "/v1/client/consumers/" + this.$route.params.id, {
              headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.$store.getters.getTokenPair.access,
              },
            })
            .then(() => {
              this.consumer.state = "delete"
              this.readOnly = true
              this.notification.success("Пользователь удален")
            })
            .catch((error) => {
              console.warn(error)
              if (error.response) {
                this.notification.error(error.response.data.message)
              } else {
                this.notification.error("Неизвестная ошибка")
              }
              this.formDisable = false
            })
      }
    },
    updateConsumer() {
      axios
          .post(API_HOST + "/v1/client/consumers/" + this.$route.params.id, this.consumer, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + this.$store.getters.getTokenPair.access,
            },
          })
          .then((response) => {
            this.consumer = response.data
            this.notification.success("Сохранено")
          })
          .catch((error) => {
            console.warn(error)
            if (error.response) {
              this.notification.error(error.response.data.message)
            } else {
              this.notification.error("Неизвестная ошибка")
            }
          })
    },
    unJoinConsumer() {
      if (confirm("Вы действительно хотите отключить клиента")) {
        axios
            .post(API_HOST + "/v1/client/consumers/unjoin", {
              "id": this.consumer.id
            }, {
              headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.$store.getters.getTokenPair.access,
              },
            })
            .then(() => {
              this.$router.push({path: `/consumers`})
            })
            .catch((error) => {
              console.warn(error)
              if (error.response) {
                this.notification.error(error.response.data.message)
              } else {
                this.notification.error("Неизвестная ошибка")
              }
            })
      }
    }
  },
  mounted() {
    this.loadConsumer()
    setTooltip()
  },
  beforeUnmount() {
    this.$store.commit("setBreadcrumbs", [])
  },
}
</script>

<style scoped></style>