<template>
  <sidenav :custom_class="color" :class="[isRTL ? 'fixed-end' : 'fixed-start']" v-if="showSidenav"/>
  <main class="main-content position-relative max-height-vh-100 h-100 overflow-x-hidden">
    <navbar
        :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']"
        :color="isAbsolute ? 'text-white opacity-8' : ''"
        :minNav="navbarMinimize"
        v-if="showNavbar"
    />
    <router-view/>
    <app-footer v-show="showFooter"/>
  </main>
  <Notification ref="notification"/>
</template>

<script>
import Sidenav from "./examples/Sidenav"
import Navbar from "@/examples/Navbars/Navbar.vue"
import AppFooter from "@/examples/Footer.vue"
import {mapMutations, mapState} from "vuex"
import Notification from "@/views/components/Notification.vue"

export default {
  name: "App",
  components: {
    Notification,
    Sidenav,
    Navbar,
    AppFooter
  },
  provide() {
    return {
      notification: {
        success: (message) => this.$refs.notification.success(message),
        error: (message) => this.$refs.notification.error(message),
        info: (message) => this.$refs.notification.info(message),
      },
    };
  },
  methods: {
    ...mapMutations(["navbarMinimize"])
  },
  computed: {
    ...mapState([
      "isRTL",
      "color",
      "isAbsolute",
      "isNavFixed",
      "navbarFixed",
      "absolute",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton",
      "userToken",
      "user",
    ])
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent"

    const sidenav = document.getElementsByClassName("g-sidenav-show")[0]

    if (window.innerWidth > 1200) {
      sidenav.classList.add("g-sidenav-pinned")
    }
  }
}
</script>
