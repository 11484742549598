<template>
  <router-link
      :data-bs-toggle="collapse ? 'collapse' : ''"
      :to="collapse ? `#${collapseRef}` : `/${collapseRef}`"
      :aria-controls="collapseRef"
      :aria-expanded="isExpanded"
      class="nav-link"
      :class="isSideActive() ? `active bg-gradient-${color}` : ''"
      v-bind="$attrs"
      @click="isExpanded = !isExpanded"
  >
    <div
        class="text-center d-flex align-items-center justify-content-center me-2"
    >
      <slot name="icon"></slot>
    </div>
    <span class="nav-link-text ms-1">{{ navText }}</span>
  </router-link>
  <div :class="isExpanded ? 'collapse show' : 'collapse'">
    <slot name="list"></slot>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  name: "SidenavCollapse",
  props: {
    collapseRef: {
      type: String,
      required: true,
    },
    navText: {
      type: String,
      required: true,
    },
    collapse: {
      type: Boolean,
      default: true,
    },
    componentsForActive: {
      type: Array,
      default: () => [],
      required: false,
    }
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  methods: {
    isSideActive() {
      let currentComponentName = null
      if (this.$route.matched.length !== 0) {
        currentComponentName = this.$route.matched[this.$route.matched.length - 1].components.default.name
      }

      console.log(this.componentsForActive, currentComponentName, this.componentsForActive.includes(currentComponentName));
      return this.componentsForActive.includes(currentComponentName)
    },
  },
  computed: {
    ...mapState(["color"]),
  },
};
</script>
